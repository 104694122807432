.orderSummaryContainer {
  background-color: gray;
}
.orderSummaryPolicy {
  font-size: 0.75rem;
  margin-top: 5px;
  text-align: left;
  padding-left: 5px;
}
.orderSummaryTitle {
  text-align: left;
  font-size: 1.6rem;
  padding-left: 2%;
  font-weight: 500;
  margin: 5px 0px;
  white-space: nowrap;
}
.orderTotalPrice {
  font-size: 1.2rem;
  margin-top: 12px;
}
