.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e1bee7 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
  border-color: #bdbdbd !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #e1bee7 !important;
}

.productImage {
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 10px;
}
.productImage:hover {
  transform: scale(1.3);
  border-radius: 10px;
}

.itemDetails p {
  font-size: 1.2rem;
  text-align: left;
}

@media only screen and (max-width: 750px) {
  .pt0 {
    padding-top: 0px !important;
  }
  .pt0 p {
    margin: 8px 0px;
    font-size: 1rem;
  }
}
