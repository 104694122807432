.stickyNav {
  position: sticky;
  z-index: 1;
  top: 0;
  color: aliceblue;
  height: 60px;
  background-color: #7b1fa2;
}

.stickyNav a {
  font-size: 1.1rem;
  color: aliceblue;
  text-decoration: none;
}

.stickyNav a:hover {
  color: #d3d3d3;
}
