.TouroBar {
  padding: 30px;
  margin-bottom: 50px;

  display: block;
}

.TouroBar p {
  text-align: left;
  padding: 0px 30px;
}

.TouroBar h3 {
  text-align: left;
  padding: 0px 30px;
}

.TouroBar img {
  border: 0.5px solid rgb(195, 195, 195);
  border-radius: 15px;
  margin-top: 25px;
}

.TouroBar a {
  text-decoration: none;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s;
}

.TouroBar h3 {
  font-family: Fjalla;
  font-size: 1.5rem;
}
