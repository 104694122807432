.navContainer {
  height: 60px;
  background-color: #050d12;
}

.navItem {
  padding: 20px;
}

.navItem a {
  color: aliceblue;
}

.navItem a:hover {
  color: rgb(255, 235, 59);
  transition: 0.3s;
}
