.tile {
  /* flex-wrap: wrap; */
  position: relative;
  width: 100px;
  height: 110px;
  margin: 5px;
  background-color: aliceblue;
  border-radius: 8px;
  color: #274472;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.day {
  padding-top: 5px;
}
.highLow {
  text-align: center;
  margin: 5px;
  position: absolute;
  bottom: 5px;
  left: -5px;
}
.highTemp {
  margin: 5px;
}
.lowTemp {
  margin: 5px;
}
.icon {
  width: 60px;
}
@media only screen and (max-width: 790px) {
  .tile {
    width: 139px;
  }
}
@media only screen and (max-width: 465px) {
  .tile {
    width: 120px;
  }
}
@media only screen and (max-width: 426px) {
  .tile {
    width: 120px;
  }
}
@media only screen and (max-width: 411px) {
  .tile {
    width: 170px;
  }
}
@media only screen and (max-width: 376px) {
  .tile {
    width: 160px;
  }
}
@media only screen and (max-width: 321px) {
  .tile {
    width: 140px;
  }
}
