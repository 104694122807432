.projSecContainer {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #050d12;
}

@media screen and (max-width: 900px) {
  .projSecContainer {
    height: 620px;
  }
  .guide {
    display: none;
  }
}
.guide {
  background-color: #050d12;
  border-radius: 10px 100px / 120px;
  width: 50%;
  left: 25%;
  position: absolute;
  top: 260px;
  color: rgb(227, 242, 253);
  font-size: 2rem;
  font-family: BebasNeue;
  padding: 40px;
}

.projSectionTitle {
  top: -50px;
  left: 10%;
  position: absolute;
  font-family: Fjalla;
}

.circle {
  cursor: pointer;
  margin-top: 40px;
  height: 60px;
  width: 60px;
  /* background-color: rgb(227, 242, 253); */
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.circle:hover {
  background-color: #f7981d;
  transition: 0.2s;
}

.circle-lable {
  margin-top: 10px;
  color: #bbb;
  font-family: Fjalla;
  font-size: 1.25rem;
}
