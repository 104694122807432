.cardWeather {
  display: inline-block;
  position: relative;
  background-color: #274472;
  padding: 10px;
  color: #c3e0e5;
  margin-top: 5%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
