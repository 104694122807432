.summaryContainer {
  position: relative;
  width: 1000px;
  padding-bottom: 60px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1100px) {
  .summaryContainer {
    width: auto;
    padding: 60px;
  }
}

@media screen and (max-width: 760px) {
  #sumTitle {
    margin-top: 0px;
  }
}

#sumTitle {
  color: #fff;
  padding-top: 10px;
  font-family: Fjalla;
  font-size: 3rem;
  text-align: left;
  margin-bottom: 0px;
  transition: transform 450ms ease;
}

#sumTitle:hover {
  cursor: pointer;
  transform: translateY(-10px);
}
#subTitle {
  color: #fff;
  font-family: Fjalla;
  font-size: 2rem;
  text-align: left;
  margin-top: 0px;
}
#profilePic {
  border-radius: 50%;
  width: 180px;
  height: 180px;
  transition: transform 450ms ease;
}
#profilePic:hover {
  cursor: pointer;
  transform: translateY(-10px);
}

.summaryContainer p {
  text-align: left;
  font-size: 1.15rem;
}
