.search {
  position: absolute;
  top: 6%;
  right: 15px;
}
.search input {
  position: relative;
  width: 250px;
  border: none;
  color: #c3e0e5;
  border-radius: 10px;
  padding: 8px 5px;
  background-color: #5885af;
  text-align: center;
}

.search p {
  position: absolute;
  bottom: -38px;
  left: 65px;
  color: #c3e0e5;
}
::placeholder {
  color: white;
}
@media only screen and (max-width: 480px) {
  .search {
    display: block;
    position: relative;
    margin-left: 15px;
  }
  .search svg {
    display: none;
  }
}
